.profile-orders {
  &__table {
    background-color: white;
  }

  &__header {
    border-bottom: 1px solid var(--grey-stroke-color);
  }

  &__list {

  }

  &__item {
    border-bottom: 1px solid var(--grey-stroke-color);
  }

  &__number {
    display: flex;
    font-weight: 600;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: var(--black-color);
  }

  &__date {
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--grey-text-color);
  }

  &__quantity {
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--black-color);
  }

  &__state-wrap {
    display: flex;
  }

  &__state {
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -0.02em;
    padding: 4px 16px;
    font-size: 14px;
    border-radius: 50px;
    flex: 0 0 auto;

    &_green {
      color: var(--green-color);
      border: 1px solid var(--green-color-15);
    }

    &_orange {
      color: var(--primary-color);
      border: 1px solid var(--primary-color-15);
    }

    &_grey {
      color: var(--grey-text-color);
      border: 1px solid var(--grey-stroke-color);
    }
  }

  &__price {
    font-weight: 600;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: var(--black-color);
  }

  &__link-wrap {
    display: flex;
    justify-content: flex-end;
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 15px;
    margin: -15px;
  }

  &__order-copy {
    flex: 0 0 20px;
    display: flex;
    width: 20px;
    height: 20px;
    cursor: copy;
    margin-left: 8px;
    svg {
      height: 100%;
      width: 100%;
    }

    &:active {
      svg path {
        stroke: var(--yellow-color);
      }
    }
  }
}

@include respond-up('large') {
  .profile-orders {
    &__header {
      padding: 28px 38px;
    }

    &__item {
      padding: 30px 38px;
      display: flex;
      align-items: center;
    }

    &__number {
      flex: 0 0 calc(var(--grid-column2) - var(--grid-gap));
      margin-right: calc(var(--grid-gap) / 2);
    }

    &__date {
      flex: 0 0 calc(var(--grid-column1) + var(--grid-gap));
      margin-right: calc(var(--grid-gap) / 2);
    }

    &__quantity {
      flex: 0 0 calc(var(--grid-column1) + var(--grid-gap));
      margin-right: calc(var(--grid-gap) / 2);
    }

    &__state-wrap {
      //flex: 0 0 calc(var(--grid-gap) * 2 + var(--grid-column1) - 4px);
      flex: 1 1 auto;
      margin-right: calc(var(--grid-gap) / 2);
    }

    &__price {
      flex: 0 0 calc(var(--grid-column2) - 22px);
      margin-right: calc(var(--grid-gap) / 2);
    }

    &__link-wrap {
      flex: 0 0 calc(var(--grid-column1) - 38px);
    }
  }
}

@include respond-up('medium') {
  .profile-orders {
    &__number {
      font-size: 18px;
    }

    &__price {
      font-size: 18px;
    }

    &__date {
      font-size: 15px;
    }

    &__quantity {
      font-size: 15px;
    }
  }
}

@include respond('medium') {
  .profile-orders {

    &__header {
      padding: 23px 28px;
    }

    &__item {
      padding: 25px 28px;
      grid-template-columns: 1fr 1fr 1fr 1fr auto;
      align-items: center;
    }

    &__number {
    }

    &__date {
    }

    &__quantity {
      grid-row: 2/3;
    }

    &__state-wrap {
    }

    &__price {
      text-align: center;
    }

    &__link {
    }
  }
}

@include respond-down('medium') {
  .profile-orders {
    &__item {
      display: grid;
      grid-gap: 0 var(--grid-gap);
    }
  }
}

@include respond-down('small') {
  .profile-orders {

    &__header {
      padding: 15px 18px;
    }

    &__item {
      grid-template-columns: repeat(4, 1fr);
      padding: 15px 18px;
      align-items: end;
    }

    &__state-wrap {
      grid-column: 1/5;
      grid-row: 1/2;
      margin-bottom: 16px;
    }

    &__number {
      font-size: 16px;
      grid-column: 1/3;
      grid-row: 2/3;
    }

    &__price {
      font-size: 16px;
      grid-column: 3/5;
      grid-row: 2/3;
      text-align: right;
    }

    &__date {
      font-size: 14px;
      grid-column: 1/5;
      grid-row: 3/4;
      margin: 6px 0 19px;
    }

    &__quantity {
      font-size: 14px;
      grid-column: 1/3;
      grid-row: 4/5;
    }

    &__link-wrap {
      grid-column: 3/5;
      grid-row: 4/5;
    }

  }
}